.bg-img {
    background-image: url(../img/hero/decore.png);
    background-size: cover;
    /* Để hình nền phủ đầy */
    background-repeat: no-repeat;
    /* Không lặp lại hình nền */
    background-position: center;
    /* Căn giữa hình nền */
}

.bg-contact {
    background-image: url(../img/contact/bg-contact.jpg);
    background-size: cover;
    /* Để hình nền phủ đầy */
    background-repeat: no-repeat;
    /* Không lặp lại hình nền */
    background-position: center;
    /* Căn giữa hình nền */
}

.sticky-booking {
    position: sticky;
    top: 20px;
    /* Khoảng cách từ phía trên màn hình */
}