.container {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
}


.logo {
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  background-color: #f6f6f6;
  /* padding: 20px; */
}

.headerSection {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.headerSection1 {
  width: 30%;
  height: 100%;
}

.headerSection2 {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: black;
}


.backgroudimage {
  color: rgb(255, 255, 255);
  font-size: 32px;
  text-decoration: underline;
  align-items: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-image: url('../img/background.png');
  background-size: cover;
  /* Điều chỉnh kích thước ảnh cho phù hợp */
  background-position: center;
  /* Đặt ảnh ở vị trí trung tâm */
}

/* Các kiểu dáng cho thanh điều hướng (navbar) */
.nav {
  background-color: #28cdc8;
  /* Màu nền của thanh điều hướng */
  width: 100%;
  /* Đặt chiều rộng của thanh điều hướng là 100% */
  /* position: absolute; */
  /* Đặt thanh điều hướng ở vị trí tuyệt đối */
  top: 0;
  /* Đặt khoảng cách từ trên cùng của trang đến thanh điều hướng */
  left: 0;
  /* Đặt thanh điều hướng ở cạnh trái của trang */
  z-index: 1001;
  /* Đảm bảo thanh điều hướng nằm trên các phần tử khác */
  transition: top 0.3s;
  /* Hiệu ứng chuyển động mượt mà khi thay đổi thuộc tính top */
}

.nav.scrolled {
  position: fixed;
  /* Đặt thanh điều hướng ở vị trí cố định khi cuộn */
  top: 0;
  /* Đặt thanh điều hướng ở phía trên cùng của trang */
  left: 0;
  /* Đặt thanh điều hướng ở cạnh trái của trang */
}

.nav ul {
  list-style-type: none;
  /* Loại bỏ dấu chấm hoặc số của danh sách */
  margin: 0;
  /* Xóa khoảng cách ngoài của danh sách */
  padding: 0;
  /* Xóa khoảng cách trong của danh sách */
  overflow: hidden;
  /* Ẩn các phần tử tràn ra ngoài khung danh sách */
  margin-left: 10%;
  /* Thụt lề bên trái của danh sách */
}

.nav li {
  float: left;
  /* Đặt các phần tử danh sách nằm ngang (trái) */
}

.nav li a {
  display: block;
  /* Hiển thị các liên kết như khối */
  color: rgb(255, 255, 255);
  /* Màu chữ của liên kết */
  text-align: center;
  /* Căn giữa chữ trong liên kết */
  padding: 14px 16px;
  /* Khoảng cách xung quanh chữ trong liên kết */
  text-decoration: none;
  /* Xóa gạch dưới của liên kết */
}

.nav li a:hover {
  background-color: #21e9e9;
  /* Màu nền của liên kết khi di chuột qua */
}

.active {
  background-color: #21e9e9;
  color: #333;
}

.main {
  flex: 1;
  padding: 20px;
}



/* src/components/Banner.css */
.banner {
  background: url("https://via.placeholder.com/800x400") no-repeat center center;
  background-size: cover;
  color: white;
  text-align: center;
  padding: 50px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
}

.banner-content {
  z-index: 1;
}

.banner-images {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.banner-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  animation: moveImage 5s infinite alternate;
}

.banner-image:nth-child(2) {
  animation-delay: 1s;
}

.banner-image:nth-child(3) {
  animation-delay: 2s;
}

@keyframes moveImage {
  0% {
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(20px);
  }
}

@media (max-width: 480px) {
  .nav ul {
    margin-left: 0px;
  }

  .nav li a {
    padding: 5px 10px;
    font-size: 12px;
  }
}