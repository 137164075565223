@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-[]
    }

    html,
    body {
        font-family: "Poppins", sans-serif;
        min-width: 100%;
        margin: 0;
        padding: 0;
        min-height: 100%;
    }

    div {
        display: block;
    }
}

@layer utilities {}