.preview-content h1 {
    font-size: 2em;
    font-weight: bold;
}
.preview-content h2 {
    font-size: 1.5em;
    font-weight: bold;
}
.preview-content h3 {
    font-size: 1.2em;
}
/* Style for ordered list */
.ql-editor ol {
    list-style-type: decimal; /* Default numbering */
    padding-left: 20px;
}

/* Style for unordered list */
.ql-editor ul {
    list-style-type: disc; /* Default bullet points */
    padding-left: 20px;
}

/* Style for list items */
.ql-editor li {
    margin-bottom: 8px; /* Add spacing between list items */
}

/* Custom preview styles */
.preview-content ol {
    list-style-type: decimal; /* Ensures ordered list numbers */
    padding-left: 20px;
}

.preview-content ul {
    list-style-type: disc; /* Bullet points for unordered list */
    padding-left: 20px;
}

.preview-content li {
    margin-bottom: 8px; /* Space between list items in the preview */
}
/* Align text in Quill editor */
.ql-editor {
    text-align: left; /* Default alignment */
}

.ql-align-center {
    text-align: center;
}

.ql-align-right {
    text-align: right;
}

.ql-align-justify {
    text-align: justify;
}

/* Optional: Styling for preview */
.preview-content {
    text-align: left; /* Default alignment */
}

.preview-content .ql-align-center {
    text-align: center;
}

.preview-content .ql-align-right {
    text-align: right;
}

.preview-content .ql-align-justify {
    text-align: justify;
}
/* Ensure images in the Quill editor are centered and have a reasonable size */
.ql-editor img {
    display: block;         /* Make images block-level */
    margin-left: auto;      /* Center image horizontally */
    margin-right: auto;
    max-width: 80%;         /* Control the image size, adjust as needed */
    height: auto;           /* Maintain aspect ratio */
    width: 10%;
}

/* Optional: Additional styling for the image preview */
.preview-content img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%; /* Adjust the percentage to control the image size */
    height: auto;   /* Maintain aspect ratio */
    width: 70%;
    margin-top: 30px ;
    margin-bottom: 30px;
    object-fit: cover;
}
/* Ensure that text color works in Quill editor */
.ql-editor .ql-color {
    color: inherit; /* Ensure the text color is inherited from inline style */
}

.ql-editor .ql-background {
    background-color: inherit; /* Ensure the background color is inherited from inline style */
}

/* Optional: Additional styling for the preview */
.preview-content .ql-color {
    color: inherit; /* Ensure the color is inherited */
}

.preview-content .ql-background {
    background-color: inherit; /* Ensure the background color is inherited */
}
.preview-content {
}

/* Tùy chỉnh thanh cuộn cho trình duyệt WebKit (Chrome, Edge, Safari)
.preview-content::-webkit-scrollbar {
    width: 8px; 
}
 */


.preview-content::-webkit-scrollbar-track {
    background: #f9f9f9; /* Nền thanh cuộn */
}

.preview-content::-webkit-scrollbar-thumb {
    background-color: #888; /* Màu thanh cuộn */
    border-radius: 10px; /* Bo góc */
    border: 2px solid #f9f9f9; /* Đường viền để tách biệt thanh cuộn */
}

.preview-content::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Màu khi hover */
}
.ql-editor {
    max-height: 300px; /* Giới hạn chiều cao */
    overflow-y: auto;  /* Hiển thị thanh cuộn dọc */
    scrollbar-width: thin; /* Thanh cuộn mỏng (Firefox) */
    scrollbar-color: #888 #f9f9f9; /* Màu sắc thanh cuộn (Firefox) */
}

/* Tùy chỉnh thanh cuộn cho trình duyệt WebKit (Chrome, Edge, Safari) */
.ql-editor::-webkit-scrollbar {
    width: 8px; /* Độ rộng thanh cuộn */
}

.ql-editor::-webkit-scrollbar-track {
    background: #f9f9f9; /* Nền thanh cuộn */
}

.ql-editor::-webkit-scrollbar-thumb {
    background-color: #888; /* Màu thanh cuộn */
    border-radius: 10px; /* Bo góc */
    border: 2px solid #f9f9f9; /* Đường viền để tách biệt thanh cuộn */
}

.ql-editor::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Màu khi hover */
}
